/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styled.css'
import Carousel from 'react-bootstrap/Carousel'
class Testimonials extends React.Component {
  render() {
    return (
      <div className="testimonial Testimonials-slider">
        <div class="container">
          <Carousel interval={7000}>
          <Carousel.Item>
            <div className="w_inner">
              <div className="inner">
                <h2>Their infectious enthusiasm and positive energy to help craft and deliver solutions that support our overall vision of innovation and improved user experiences make them a powerful digital partner and valued member of the solutions team.</h2>
                <span className="attribution">Nadia van der Nest, Strategic Projects Manager, Sanlam Private Wealth</span>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="w_inner">
              <div className="inner">
                <h2>They bring a sense of genius and of machiavellian change in terms of driving everything that we do. It's just been an absolute honour to work with them and we look forward to continue working with them in the future.</h2>
                <span className="attribution">Sinethemba Mafanya, GM Diageo Empowerment Trust SA</span>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="w_inner">
              <div className="inner">
                <h2>Urbian's out-the-box thinking continues to help us reach a saturated fatigued market by creating a platform that customers truly want to be part of.</h2>
                <span className="attribution">Keith Elliott, CEO of Met-S Care</span>
              </div>
            </div>
          </Carousel.Item>
          </Carousel>
        </div>
      </div>
    )
  }
}

export default Testimonials
