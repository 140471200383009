import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import './styled.css'


import Reactjsicon from '../../../assets/images/react.svg'
import Graphqlicon from '../../../assets/images/graphql.svg'
import RoundSketch from '../../../assets/images/ServicesScribble.svg'
import LeftArrowSketch from '../../../assets/images/leftarrowsketch.svg'
import PythonDjangoicon from '../../../assets/images/python.svg'
import ElasticSearchicon from '../../../assets/images/elastic.svg'
import AWSicon from '../../../assets/images/aws.svg'
import GCPicon from '../../../assets/images/gcp.svg'
import Wagtailicon from '../../../assets/images/wagtail.svg'
import Pandasicon from '../../../assets/images/pandas.svg'

const Servicesection = () => {
    const serviceImages = useStaticQuery(graphql`
      query ServiceImages {
        serviceMain: file(absolutePath: {regex: "/service-img.png/"}) {
          childImageSharp {
            sizes(maxWidth: 1200) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        second: file(absolutePath: {regex: "/S-2020.png/"}) {
          childImageSharp {
            sizes(maxWidth: 500) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        third: file(absolutePath: {regex: "/desgin-layer.png/"}) {
          childImageSharp {
            sizes(maxWidth: 600) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        fourth: file(absolutePath: {regex: "/Software.png/"}) {
          childImageSharp {
            sizes(maxWidth: 600) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <div>
        <Container className="border-top servicesection spb-10">
          <Row>
            <Col md="12" className="offset-lg-1 col-lg-10 offset-xl-2 col-xl-8 text-center">
              <h2>
                We' re a new kind of consultancy. <br />
                Our secret sauce is our ability
                <br />{' '}
                <Link to="/read" className="fbutton">
                  to bridge the strategy to delivery gap.
                  <svg
                    className="styled__Arrow-fdeFVt fRPwvC"
                    width="14"
                    height="10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g stroke="#030303" fill="none" fillRule="evenodd">
                      <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                      <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                    </g>
                  </svg>
                </Link>
              </h2>
            </Col>
          </Row>
          <Row className="spt-5 sp-10">
            <Col sm="12" className="text-center">
              <Image
                sizes={serviceImages.serviceMain.childImageSharp.sizes}
              />
            </Col>
          </Row>
          <Row className="rel service-list uop">
            <Col lg="3" md="6">
              <h6>Incubators, Accelerators, Programs & Labs</h6>
              <p>
                The return on Accelerators, Incubators, Innovation Programs and
                R&D Labs worldwide is abysmal. We've spent a lot of time
                learning about what works and what doesn't from running them
                ourselves.
              </p>
            </Col>
            <Col lg="3" md="6">
              <h6>Digital Product Innovation </h6>
              <p>
                Whether you're looking to launch a consumer facing product or
                new service aimed at businesses we can help you. We are often
                able to get a new breakthrough product in your customers hands
                in 100 days.
              </p>
              <Link
                to="/services/digitalproductinnovation/"
                className="styled__Link-lfEBUk iUBhrC servcclink dosD"
              >
                More on digital products
                <svg
                  className="styled__Arrow-fdeFVt fRPwvC"
                  width="14"
                  height="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="#030303" fill="none" fillRule="evenodd">
                    <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                    <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                  </g>
                </svg>
              </Link>
            </Col>
            <Col lg="3" md="6">
              <h6>Digital Transformation</h6>
              <p>
                We work alongside your teams to tackle the toughest challenges
                head on.
              </p>
              <Link
                to="/services/digitaltransformation"
                className="styled__Link-lfEBUk iUBhrC servcclink dosD"
              >
                More on transformation
                <svg
                  className="styled__Arrow-fdeFVt fRPwvC"
                  width="14"
                  height="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="#030303" fill="none" fillRule="evenodd">
                    <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                    <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                  </g>
                </svg>
              </Link>
            </Col>
            <Col lg="3" md="6">
              <h6>New capabilities.</h6>
              <p>
                We have built long-lasting capability inside companies through
                hiring, onboarding and coaching multi-disciplinary teams.
              </p>
              <Link
                to="/services/newcapabilities/"
                className="styled__Link-lfEBUk iUBhrC servcclink dosD"
              >
                More on this service
                <svg
                  className="styled__Arrow-fdeFVt fRPwvC"
                  width="14"
                  height="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="#030303" fill="none" fillRule="evenodd">
                    <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                    <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                  </g>
                </svg>
              </Link>
            </Col>
            <img src={RoundSketch} className="ringsketch" />
          </Row>
          <Row className="spt-15">
            <Col lg="3" md="12">
              <h3 className="rel">
                Everything we do is about building digital products
                <img src={LeftArrowSketch} className="leftarrowsketch" />
              </h3>
            </Col>
            <Col  md="12" lg="6" className="offset-lg-2">
              <p>
                We're one of a very few agencies that can take a product idea
                form end to end. From the first discovery meeting to a real
                shipped product. Have a great new product idea and nobody to
                start building it?{' '} <a href="/contact/" title="Get in touch with us" className="yellowBackground">We're your team.</a>
              </p>
            </Col>
          </Row>
          <Row>
          	<Col className="grid-serv offset-lg-1 col-lg-10 offset-xl-2 col-xl-8">
	            <div className="services-list-min row">
	              <div className="service-item col-md-4">
	                <strong>PM &amp; Strategy </strong>
	                <ul>
	                  <li>Product strategy</li>
	                  <li>Brand strategy</li>
	                  <li>Business strategy</li>
	                  <li>Product management</li>
	                  <li>Post-launch capacity building</li>
	                </ul>
	              </div>
	              <div className="service-item col-md-4">
	                <strong>Product Design</strong>
	                <ul>
	                  <li>UX research &amp; testing</li>
	                  <li>Rapid prototyping</li>
	                  <li>User experience</li>
	                  <li>User interface</li>
	                  <li>Brand development</li>
	                  <li>Content &amp; Writing</li>
	                  <li>Illustration</li>
	                  <li>Motion &amp; Video</li>
	                </ul>
	              </div>
	              <div className="service-item col-md-4">
	                <strong>Development</strong>
	                <ul>
	                  <li>Technical architecture</li>
	                  <li>Front-end engineering</li>
	                  <li>Web-App development</li>
	                  <li>Mobile App Development</li>
	                  <li>API &amp; CMS Development</li>
	                  <li>Code auditing &amp; QA</li>
	                  <li>DevOps</li>
	                </ul>
	              </div>
	            </div>
	          	<div className="services-list-min row">
	            	<div className="service-item col-md-4">
		              <strong>Industry</strong>
		              <ul>
		                <li>Health & Wellness</li>
		                <li>
		                  <Link to="/services/insurance">Insurance</Link>
		                </li>
		                <li>Financial services</li>
		                <li>E-commerce</li>
		                <li>SaaS</li>
		              </ul>
		            </div>
	            	<div className="service-item col-md-4">
		              <strong>Teams & Process</strong>
		              <ul>
		                <li>Level up your team</li>
		                <li>Level up your remote team</li>
		                <li>Code audit</li>
		                <li>Mentoring</li>
		                <li>Deployment</li>
		                <li>Help hiring</li>
		              </ul>
	            	</div>
                <div className="service-item col-md-4">
                  <strong>Tools</strong>
                  <ul>
                    <li><Link to="/services/designsystems/">Design systems</Link></li>
                    <li><Link to="/services/companyhandbooks/">Company handbooks</Link></li>
                  </ul>
                </div>
	            </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="sp-10 Producstrategy">
            <Col md="6" lg="5">
              <div id="producstrategy">
                <div class="producstrategy_content">
                  <p>#Product strategy notes ver. 1.0#</p>
                  <p>We focus on digital products that meet real customer needs, open up markets and generate new sources of revenue. Breakthrough digital strategy is built on powerful customer insights, sensitivity to existing assets and operational impact and the rapid execution of ideas to test and evolve the strategy.</p>
                </div>
                <div class="producstrategy_controls">
                  <span>Body</span>
                  <span>Heading 1<svg width="8" height="8"><g stroke="currentColor" fill="none" fill-rule="evenodd"><path d="M1 3l3-3 3 3M1 5l3 3 3-3"></path></g></svg></span>
                  <span>List<svg width="8" height="8"><g stroke="currentColor" fill="none" fill-rule="evenodd"><path d="M1 3l3-3 3 3M1 5l3 3 3-3"></path></g></svg>
                  </span>
                  <span>Quote</span>
                  <span></span>
                  <span>Italic</span>
                  <span>Bold</span>
                  <span>Strikethrough</span>
                </div>
              </div>
            </Col>
            <Col md="6" lg="6" className="offset-lg-1">
              <h2>Product strategy</h2>
              <p>
                We focus on digital products that meet real customer needs, open
                up markets and generate new sources of revenue. Breakthrough
                digital strategy is built on powerful customer insights,
                sensitivity to existing assets and operational impact and the
                rapid execution of ideas to test and evolve the strategy.
              </p>
              <h6 className='flist'>Our development services</h6>
              <ul>
                <li>Product strategy</li>
                <li>Brand strategy </li>
                <li>Business Strategy</li>
                <li>Product management</li>
                <li>Post-launch capacity building</li>
              </ul>
              <div className="casestudyhighlight spt-5">
              	<span>Related Project</span>
	              <h4>
	                From an idea to over 2,000 rural farmers registered in 4 months.
	              </h4>
	              <a title="View the project" target="_blank" class="styled__Link-lfEBUk iUBhrC font-weight-normal" href="http://grownationfund.com/index.html"
	              >
	                View the project
	                <svg
	                  className="styled__Arrow-fdeFVt fRPwvC"
	                  width="14"
	                  height="10"
	                  xmlns="http://www.w3.org/2000/svg"
	                >
	                  <g stroke="#030303" fill="none" fillRule="evenodd">
	                    <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
	                    <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
	                  </g>
	                </svg>
	              </a>
	            </div>
            </Col>
          </Row>
          <Row className="sp-8 product-design">
            <Col md="6" lg="6">
              <h2>Product design</h2>
              <div className="position-relative m-30">
                <p>
                  We help you gain the creative confidence to excel at consumer
                  research and rapidly prototype your product idea continually
                  testing with users in the wild. Our designers-who-code are
                  able to exquisitely craft pixel perfect user experiences and
                  can most often go from prototype to product launch in under
                  100 days.
                </p>
                <div className="kUmLiD">
                  <span className=" bzkOoc"></span>
                  <span className=" bzkOoc"></span>
                  <span className=" bzkOoc"></span>
                  <span className=" bzkOoc"></span>
                  <span className=" bzkOoc"></span>
                  <span className=" bzkOoc"></span>
                </div>
              </div>
              <h6 className='flist'>Our product design services</h6>
              <ul>
                <li>UX research & testing </li>
                <li>Rapid prototyping</li>
                <li>User experience</li>
                <li>User interface</li>
                <li>Brand development</li>
                <li>Content & Writing</li>
                <li>Illustration </li>
                <li>Motion & Video</li>
              </ul>
              <div className="casestudyhighlight spt-5">
              	<span>Related Project</span>
	              <h4>
	                A design system led new brand home generates R150 million of new business in 6 months.
	              </h4>
	              <a title="View the design system" target="_blank"
	                class="styled__Link-lfEBUk iUBhrC font-weight-normal"
	                href="https://sanlamprivatewealth.designsystem.co.za/"
	              >
	                View the design system
	                <svg
	                  className="styled__Arrow-fdeFVt fRPwvC"
	                  width="14"
	                  height="10"
	                  xmlns="http://www.w3.org/2000/svg"
	                >
	                  <g stroke="#030303" fill="none" fillRule="evenodd">
	                    <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
	                    <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
	                  </g>
	                </svg>
	              </a>
	            </div>
            </Col>
            <Col md="6" lg="5" className="offset-lg-1">
              <Image
                sizes={serviceImages.third.childImageSharp.sizes}
              />
            </Col>
          </Row>
        </Container>
        <div className="Softwaredevelopment spb-8">
          <Container>
            <Row>
              <Col md="6" lg="5">
                <div id="softwaredevelopment">
                  <div class="sft_row">
                    <span>01</span>
                    <span>
                      <i>import</i>&nbsp;React&nbsp;<i>from</i>&nbsp;
                      <span class="green">"react"</span>
                    </span>
                  </div>
                  <div class="sft_row">
                    <span>02</span>
                  </div>
                  <div class="sft_row">
                    <span>03</span>
                    <span>
                      <i>const</i>&nbsp;
                      <span class="blue">Development</span>&nbsp;
                      <span class="teal">=</span>&nbsp;<span class="purple">()</span>&nbsp;
                      <span class="teal">&#61;&gt;</span>&nbsp;
                      <span class="purple">&#123;</span>
                    </span>
                  </div>
                  <div class="sft_row">
                    <span>04</span>
                    <span class="tab1">return&nbsp;<span class="purple">(</span></span>
                  </div>
                  <div class="sft_row">
                    <span>05</span>
                    <span class="tab2 purple">&lt;&gt;</span>
                  </div>
                  <div class="sft_row">
                    <span>06</span>
                    <span class="tab3 purple">&lt;</span>
                    <span class="yellow">Title</span>
                    <span class="purple">&gt;</span>
                    Software Development
                    <span class="purple">&lt;/</span>
                    <span class="yellow">Title</span>
                    <span class="purple">&gt;</span>
                  </div>
                  <div class="sft_row">
                    <span>07</span>
                    <span class="tab3 purple">&lt;</span>
                    <span class="yellow">Text</span>
                    <span class="purple">&gt;</span>
                  </div>
                  <div class="sft_row">
                    <span>08</span>
                    <span class="tab4">
                      There&nbsp;<span class="green">'s an obsession with detail when it comes to developing what'</span>s
                    </span>
                  </div>
                  <div class="sft_row">
                    <span>09</span>
                    <span class="tab4">envisioned and that’s why we’ve adopted some of the industry's best
                    </span>
                  </div>
                  <div class="sft_row">
                    <span>10</span>
                    <span class="tab4">
                      practices for Code Review<span class="purple">,</span> Quality Assurance&nbsp;<span class="blue">&</span>&nbsp;Continuous Deployment<span class="purple">.</span>
                    </span>
                  </div>
                  <div class="sft_row">
                    <span>11</span>
                    <span class="tab4">
                      React is our&nbsp;<span class="red">true</span> workhorse and our unquestionable tool of choice to
                    </span>
                  </div>
                  <div class="sft_row">
                    <span>12</span>
                    <span class="tab4">develop almost anything<span class="purple">.</span>
                    </span>
                  </div>
                  <div class="sft_row">
                    <span>13</span>
                    <span class="tab3">
                      <span class="purple">&lt;/</span>
                      <span class="yellow">Text</span>
                      <span class="purple">&gt;</span>
                    </span>
                  </div>
                  <div class="sft_row">
                    <span>14</span>
                    <span class="tab2 purple">&lt;/&gt;</span>
                  </div>
                  <div class="sft_row">
                    <span>15</span>
                    <span class="tab1">)</span>
                  </div>
                  <div class="sft_row">
                    <span>16</span><span class="purple">&#125;</span>
                  </div>
                  <div class="sft_row">
                    <span>17</span>
                  </div>
                  <div class="sft_row">
                    <span>18</span><span><i>export&nbsp;default</i>&nbsp;Development</span>
                  </div>
                  <div class="sft_row">
                    <span>19</span>
                  </div>
                </div>
              </Col>
              <Col md="6" lg="6" className="offset-lg-1">
                <h2>Software development</h2>
                <p>
                  Whether you're building something from scratch or improving an existing product, our design and development consultants have you covered. Enjoy the ease of pulling in senior developers and designers to work alongside your team and enhance the speed and quality of your project for a flexible amount of time. We have an obsession with quality and detail when it comes to development and that's why we've adopted some of the industry's best practices for Code Review, Quality Assurance & Continuous Deployment. While we have a rich history with Django and Python, we use a range of technologies like React and others, to utilize the best tools for your application.
                </p>
                <h6 className='flist'>Our development services</h6>
                <ul>
                  <li>Technical architecture</li>
                  <li>Front-end engineering</li>
                  <li>Web-App development</li>
                  <li>iOS & Android Mobile App development</li>
                  <li>API & CMS development</li>
                  <li>Code auditing & QA</li>
                  <li>DevOps</li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="spt-5 sp-3">
                <p>We love tech. But we only use what's right for the job.</p>
              </Col>
            </Row>
            <Row className="tech-stack">
              <Col sm="3" xs="6" className="mt-5 mb-3">
                <div class="icobx">
                  <img src={Reactjsicon} alt="React + React Native" />
                </div>
                <em>React + React Native</em>
              </Col>
              <Col sm="3" xs="6" className="mt-5 mb-3">
                <div class="icobx">
                  <img src={Graphqlicon} alt="GraphQL" />
                </div>
                <em>GraphQL</em>
              </Col>
              <Col sm="3" xs="6" className="mt-5 mb-3">
                <div class="icobx">
                  <img src={PythonDjangoicon} alt="Python and Django" />
                </div>
                <em>Python + Django</em>
              </Col>
              <Col sm="3" xs="6" className="mt-5 mb-3">
                <div class="icobx">
                  <img src={ElasticSearchicon} alt="Elastic Search" />
                </div>
                <em>Elastic Search</em>
              </Col>
              <Col sm="3" xs="6" className="mt-5">
                <div class="icobx">
                  <img src={AWSicon} alt="AWS" />
                </div>
                <em>AWS</em>
              </Col>
              <Col sm="3" xs="6" className="mt-5">
                <div class="icobx">
                  <img src={GCPicon} alt="Google Cloud Platform" />
                </div>
                <em>Google Cloud Platform</em>
              </Col>
              <Col sm="3" xs="6" className="mt-5">
                <div class="icobx">
                  <img src={Wagtailicon} alt="Wagtail" />
                </div>
                <em>Wagtail</em>
              </Col>
              <Col sm="3" xs="6" className="mt-5">
                <div class="icobx">
                  <img src={Pandasicon} alt="Pandas" />
                </div>
                <em>Pandas</em>
              </Col>
            </Row>
            <Row>
              <Col md="6" lg="6" className="offset-lg-6 spt-8">
              <div className="casestudyhighlight">
              	<span>Related Project</span>
	              <h4>A fully underwritten online insurance experience launched in record time for Standard Bank.</h4>
	              <Link title="Read more in the project"
	                className="styled__Link-lfEBUk iUBhrC font-weight-normal"
	                to="/read/standard-bank-steps-into-the-life-insurance-gap/"
	              >
	                Read more on what we delivered
	                <svg
	                  className="styled__Arrow-fdeFVt fRPwvC"
	                  width="14"
	                  height="10"
	                  xmlns="http://www.w3.org/2000/svg"
	                >
	                  <g stroke="#030303" fill="none" fillRule="evenodd">
	                    <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
	                    <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
	                  </g>
	                </svg>
	              </Link>
	            </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  }


export default Servicesection
