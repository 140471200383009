/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

import { Topsection, Worksection, Servicesection } from '../components/Services'
import Gettouch from '../components/Gettouch'
import Testimonials from '../components/Testimonials'
import Headinfo from '../components/Headinfo'
interface IContent {
  
}

const Contact: React.FC<IContent> = ({
 
}) => {
  return (
    <div className="services_min">
      <Layout>
        <Topsection></Topsection>
        <Testimonials></Testimonials>
        <Worksection></Worksection>
        <Servicesection></Servicesection>
        <Headinfo></Headinfo>
        <Gettouch></Gettouch>
      </Layout>
    </div>
  )
}

export default Contact


