/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import styled from 'styled-components'
import './styled.css'

const Topsection = () => {
    const topImage = useStaticQuery(graphql`
      query TopImage {
        top: file(absolutePath: {regex: "/ServicesBanner-img.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 2400) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <Container className="introduction content-wrap">
        <Row className="sp-5">
          <Col md="8" lg="7" xl="6">
            <h1>Shipping is believing.</h1>
            <p>
              We ask our clients to participate directly with us in an intensely
              collaborative process based on specific KPIs, rapid prototyping,
              and continuous testing and improvement. The result is one combined
              team making things the right way with fewer project hours
              invested in presentations.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Image sizes={topImage.top.childImageSharp.sizes} alt="We ask our clients to participate directly with us in an intensely
              collaborative process based on specific KPIs, rapid prototyping, and continuous testing and improvement."/>
          </Col>
        </Row>
      </Container>
    )
  }

export default Topsection
